import * as React from "react"
import clsx from "clsx"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Divider from "@material-ui/core/Divider"
import Box from "@material-ui/core/Box"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  descriptionText: {
    fontSize: "1.3rem",
    lineHeight: "1.4rem",
    display: "inline",

    overflowWrap: "anywhere",
    fontWeight: 600,
    color: theme.palette.common.blackFourteen,
  },

  descriptionTextBold: {
    color: theme.palette.common.blackEight,
    marginRight: "0.3rem",
    lineHeight: "1.3rem",
    display: "inline",
    fontWeight: 500,
  },

  descriptionTextBox: {
    lineHeight: 0,
    marginBottom: "0.35rem",
  },

  itemHeading: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "0.9rem",

    color: theme.palette.common.orange,
  },

  divider: {
    marginTop: "0.75rem",
    marginBottom: "1rem",
    backgroundColor: theme.palette.common.blackFour,
  },

  quantityHeading: {
    fontWeight: 700,
    fontSize: "1.3rem",
    lineHeight: "1.2rem",
  },

  quantityChip: {
    paddingTop: "0.25rem",
    paddingBottom: "0.15rem",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "2rem",
    marginRight: "0.5rem",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.blackFour,
  },

  quantityChipRed: {
    backgroundColor: theme.palette.common.darkOrange,
  },

  titleGrid: {
    marginBottom: "0.25rem",
  },
}))

const SingleOrderItemLine = ({ item }) => {
  const classes = useStyles()

  return (
    <>
      {<Divider classes={{ root: classes.divider }} />}
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        classes={{ root: classes.titleGrid }}
      >
        <Grid item>
          <Box
            classes={{
              root: clsx(classes.quantityChip, {
                [classes.quantityChipRed]: item.qty > 1,
              }),
            }}
          >
            <Typography
              variant="h6"
              classes={{ root: classes.quantityHeading }}
            >
              {`${item.qty}x`}
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h6" classes={{ root: classes.itemHeading }}>
            {item.name}
          </Typography>
        </Grid>
      </Grid>

      {Object.keys(item.orderSelectedItems).map((objkey, index) =>
        item.orderSelectedItems[objkey].fieldPrintLabel ? (
          <Box key={index} classes={{ root: classes.descriptionTextBox }}>
            <Typography
              variant="body1"
              classes={{
                root: clsx(
                  classes.descriptionText,
                  classes.descriptionTextBold
                ),
              }}
            >
              {`-${item.orderSelectedItems[objkey].fieldPrintLabel}`}
            </Typography>

            {item.orderSelectedItems[objkey].selectedItems[0].optionTextVal ? (
              <Typography
                variant="body1"
                classes={{ root: classes.descriptionText }}
              >
                {`${item.orderSelectedItems[objkey].selectedItems[0].optionTextVal}`}
              </Typography>
            ) : (
              item.orderSelectedItems[objkey].selectedItems.map(
                (inSelectedItem, index) => {
                  return (
                    <Typography
                      variant="body1"
                      classes={{ root: classes.descriptionText }}
                      key={index}
                    >
                      {`${
                        item.orderSelectedItems[objkey].fieldType ===
                          "quantityBox" || inSelectedItem.quantity > 1
                          ? `${inSelectedItem.quantity}x `
                          : ""
                      }${inSelectedItem.optionPrintLabel}${
                        index + 1 <
                        item.orderSelectedItems[objkey].selectedItems.length
                          ? `, `
                          : ""
                      }`}
                    </Typography>
                  )
                }
              )
            )}
          </Box>
        ) : null
      )}
    </>
  )
}

export default SingleOrderItemLine
