import * as React from "react"
import axios from "axios"
import {
  setOrdTimesNAlarm,
  refreshOrdTimesNAlarm,
  addSingleOrder,
} from "../helperFunc/sectionMainImportFilter"
import MainSectionInCont from "./mainSectionInCont"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import { socket } from "../../sockets/web-sockets"
import handleError from "../../../helperFunc/handleError"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100vh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "80%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))

const MainSectionPanel = ({
  user,
  kitchenSettings,
  printer,
  dispatchFeedback,
  setAlarm,
  storeSettings,
  section,
  setCurrentOrdCount,
  selectedOrderStateOption,
  selectedDetailLvl,
}) => {
  const [ordersIn, setOrdersIn] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  const classes = useStyles()

  const ordersInRef = React.useRef([])
  const timerRef = React.useRef(null)
  const repeatCounter = React.useRef(0)

  ordersInRef.current = ordersIn

  const timeToRefresh = 3 // (5) * 6 * 10000

  const refreshOrderTimes = React.useCallback(() => {
    //console.log("refreshOrderTimes")
    const { orderRes, alarm, ordCount } = refreshOrdTimesNAlarm(
      ordersInRef.current,
      kitchenSettings,
      section
    )

    setOrdersIn(orderRes)
    setAlarm(alarm)
    setCurrentOrdCount(ordCount)
  }, [kitchenSettings, setAlarm, section, setCurrentOrdCount])

  const handleAddSingleOrder = React.useCallback(
    data => {
      //console.log("handleAddSingleOrder")
      const res = addSingleOrder(
        ordersInRef.current,
        data,
        kitchenSettings,
        section
      )
      if (res) {
        setOrdersIn(res.orderRes)
        setAlarm(res.alarm)
        setCurrentOrdCount(res.ordCount)
      }
    },
    [kitchenSettings, setAlarm, section, setCurrentOrdCount]
  )

  const getFirstOrders = React.useCallback(
    isSetLoading => {
      axios
        .post(
          process.env.GATSBY_STRAPI_URL +
            "/api/kitchen-setting/first-section-get-all-orders",
          {
            section: section,
          },
          {
            headers: {
              Authorization: `Bearer ${user.jwt}`,
            },
          }
        )
        .then(response => {
          const ordersToFilter = [
            ...response.data.doneOrders,
            ...response.data.workingOrders,
          ]
          const { orderRes, alarm, ordCount } = setOrdTimesNAlarm(
            ordersToFilter,
            kitchenSettings,
            section
          )
          setOrdersIn(orderRes)
          setAlarm(alarm)
          setCurrentOrdCount(ordCount)
          if (isSetLoading) {
            setLoading(false)
          }
        })
        .catch(error => {
          console.error(error)
          if (isSetLoading) {
            setLoading(false)
          }
          handleError(error, dispatchFeedback)
        })
    },
    [
      dispatchFeedback,
      user.jwt,
      kitchenSettings,
      setAlarm,
      section,
      setCurrentOrdCount,
    ]
  )

  React.useEffect(() => {
    getFirstOrders(true)
  }, [getFirstOrders])

  React.useEffect(() => {
    if (socket) {
      socket.on("newOrderReceived", data => {
        handleAddSingleOrder(data)
      })
      socket.on("orderUpdated", data => {
        handleAddSingleOrder(data)
      })
    }
  }, [handleAddSingleOrder])

  React.useEffect(() => {
    if (socket) {
      socket.on("connect_error", () => {
        if (typeof window !== "undefined") {
          timerRef.current = setTimeout(() => {
            window.location.reload()
          }, 60000)
        }
      })
    }
    return () => clearInterval(timerRef.current)
  }, [])

  React.useEffect(() => {
    if (socket) {
      socket.on("connect", () => {
        console.log("ReConnected")
        getFirstOrders(false)
      })
    }
  }, [getFirstOrders])

  React.useEffect(() => {
    if (socket) {
      socket.on("refreshAllDisplays", data => {
        if (data) {
          if (typeof window !== "undefined") {
            window.location.reload()
          }
        }
      })
    }
  }, [])

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (repeatCounter.current < timeToRefresh * 6) {
        repeatCounter.current = repeatCounter.current + 1
        refreshOrderTimes()
      } else {
        repeatCounter.current = 0
        getFirstOrders(false)
      }
    }, 10000)

    return () => clearInterval(interval)
  }, [refreshOrderTimes, getFirstOrders])

  //console.log("Main Rend")

  return (
    <>
      {loading ? (
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <CircularProgress color="inherit" />
          </Box>
        </Box>
      ) : (
        <MainSectionInCont
          user={user}
          kitchenSettings={kitchenSettings}
          printer={printer}
          dispatchFeedback={dispatchFeedback}
          setAlarm={setAlarm}
          ordersIn={ordersIn}
          storeSettings={storeSettings}
          setOrdersIn={setOrdersIn}
          section={section}
          setCurrentOrdCount={setCurrentOrdCount}
          selectedOrderStateOption={selectedOrderStateOption}
          selectedDetailLvl={selectedDetailLvl}
        />
      )}
    </>
  )
}

export default MainSectionPanel
