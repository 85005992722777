import * as React from "react"
import clsx from "clsx"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Avatar from "@material-ui/core/Avatar"
import Badge from "@material-ui/core/Badge"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import OrderItemLine from "./orderItemLine"
import LinearProgress from "@material-ui/core/LinearProgress"
import Popover from "@material-ui/core/Popover"
import getTimeText from "../helperFunc/getTimeText"
import OrderListItemFooter from "./orderListItemFooter"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import CenterFocusStrongOutlinedIcon from "@mui/icons-material/CenterFocusStrongOutlined"
import HourglassBottomOutlinedIcon from "@mui/icons-material/HourglassBottomOutlined"
import { makeStyles, withStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  orderListItemBoxOuter: {
    marginRight: "0.75rem",
    marginBottom: "0.75rem",
    borderRadius: "0.5rem",

    height: ({ selectedDetailLvl }) =>
      selectedDetailLvl.value === "Detailed"
        ? "calc(50vh  - 52px)"
        : selectedDetailLvl.value === "Brief"
        ? "calc(34vh  - 52px)"
        : "11.9rem",
    transition: "all 0.75s",
    backgroundColor: theme.palette.common.black,
  },
  orderListItemBox: {
    position: "relative",
    borderRadius: "0.5rem",
    paddingTop: "0.25rem",
    height: "100%",
    backgroundColor: theme.palette.common.black,
  },

  dineInMainBox: {
    backgroundColor: `${theme.palette.common.dineIn} !important`,
  },

  selectedDineInMainBox: {
    backgroundColor: `${theme.palette.common.dineInDark} !important`,
  },

  headerGrid: {
    paddingTop: "0.5rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    marginBottom: "0.25rem",
  },

  orderNoText: {
    fontSize: "2rem",
    lineHeight: "2rem",

    fontWeight: 700,
  },

  textLabel: {
    fontSize: "1.25rem",
    fontWeight: 700,
    marginTop: "0.25rem",

    lineHeight: "0.75rem",
    marginBottom: "0.35rem",
    color: theme.palette.common.seventyWhite,
  },

  footer: {
    position: "absolute",
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: theme.palette.common.blackThree,
  },

  timeBox: {
    borderBottomRightRadius: "0.5rem",
    borderBottomLeftRadius: "0.5rem",
    backgroundColor: `${theme.palette.common.blackFive} !important`,
  },

  dineInMainBoxWarning: {
    backgroundColor: `${theme.palette.common.darkOrange} !important`,
  },

  dineInMainBoxDanger: {
    backgroundColor: `${theme.palette.common.darkRed} !important`,
  },

  timeBoxText: {
    fontWeight: 400,
    color: theme.palette.common.offWhite,
    fontSize: "0.75rem",
    textTransform: "uppercase",
  },

  statusText: {
    fontWeight: 600,
    fontSize: "1.25rem",
    lineHeight: "1rem",
    textTransform: "uppercase",
  },

  ordStatusText: {
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "0.7rem",
    textTransform: "uppercase",
    marginBottom: "0.1rem",
  },

  ordStatusHeading: {
    fontWeight: 400,
    fontSize: "1rem",
    lineHeight: "0.7rem",
    marginRight: "0.5rem",
    marginBottom: "0.1rem",
  },

  timeText: {
    fontWeight: 400,
    fontSize: "1.25rem",
    lineHeight: "1rem",
  },

  butMain: {
    padding: "0.45rem",
  },

  butDone: {
    padding: "0.35rem",
    backgroundColor: theme.palette.common.done,
    color: theme.palette.common.white,
    "&:hover": {
      backgroundColor: `${theme.palette.common.darkGreen} !important`,
    },
  },

  butDoneDisabled: {
    backgroundColor: `${theme.palette.common.darkGreen} !important`,
  },

  butDoneText: {
    textTransform: "initial",
    fontSize: "0.75rem",
  },
  iconSize: {
    fontSize: "2.5rem !important",
  },

  progressWarning: {
    backgroundColor: `${theme.palette.common.orange} !important`,
  },

  progressDanger: {
    backgroundColor: `${theme.palette.common.red} !important`,
  },

  ordStatusGrid: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.15rem",
    backgroundColor: theme.palette.common.received,

    borderTopStyle: "solid",
    borderTopColor: theme.palette.common.black,
    borderTopWidth: "2px",
  },

  statusGrid: {
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    backgroundColor: theme.palette.common.received,
  },

  statusGridAlarm: {
    animationName: `$myEffect`,

    animationIterationCount: "infinite",
    animationDuration: "1.5s",
    animationTimingFunction: `${theme.transitions.easing.easeInOut}`,
  },

  "@keyframes myEffect": {
    "0%": {
      backgroundColor: `${theme.palette.common.orangeRed}`,
    },

    "20%": {
      backgroundColor: `${theme.palette.common.received}`,
    },

    "40%": {
      backgroundColor: `${theme.palette.common.orangeRed}`,
    },
    "60%": {
      backgroundColor: `${theme.palette.common.received}`,
    },
    "80%": {
      backgroundColor: `${theme.palette.common.orangeRed}`,
    },
    "100%": {
      backgroundColor: `${theme.palette.common.orangeRed}`,
    },
  },

  statusGridWarning: {
    backgroundColor: `${theme.palette.common.darkOrange}`,
  },
  statusGridPreparing: {
    backgroundColor: `${theme.palette.common.preparing}`,
  },
  statusGridReady: {
    backgroundColor: `${theme.palette.common.ready}`,
  },
  statusGridDone: {
    backgroundColor: `${theme.palette.common.done}`,
  },

  itemHeading: {
    paddingTop: "0.15rem",
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "1.35rem",
    color: theme.palette.common.redSelected,
  },

  descriptionText: {
    fontSize: "1em",
    lineHeight: "1.3rem",
    color: theme.palette.common.blackEight,
    overflowWrap: "anywhere",
    fontWeight: 400,
    marginBottom: "0.25rem",
  },
  dividerLine: {
    height: "3px",
  },
  detailsBox: {
    paddingLeft: "1rem",
    paddingRight: "1rem",

    backgroundColor: theme.palette.common.black,
    overflowY: "auto",
    borderBottomLeftRadius: "0.5rem",
    borderBottomRightRadius: "0.5rem",
    height: ({ selectedDetailLvl }) =>
      selectedDetailLvl.value === "Detailed"
        ? "calc(50vh  - 175px)"
        : selectedDetailLvl.value === "Brief"
        ? "calc(34vh  - 175px)"
        : 0,
    paddingBottom: ({ selectedDetailLvl }) =>
      selectedDetailLvl.value === "Detailed" ||
      selectedDetailLvl.value === "Brief"
        ? "4rem"
        : 0,
    paddingTop: ({ selectedDetailLvl }) =>
      selectedDetailLvl.value === "Detailed" ||
      selectedDetailLvl.value === "Brief"
        ? "0.5rem"
        : 0,
    transition: "all 0.75s",
  },
  gridSizing: {
    gridColumnEnd: "span 2",

    [theme.breakpoints.down("lg")]: {
      gridColumnEnd: "span 4",
    },

    [theme.breakpoints.down("sm")]: {
      gridColumnEnd: "span 12",
    },
  },
  waitingTextHead: {
    fontWeight: 800,
    marginRight: "1rem",
  },

  waitingTextHeadSecond: {
    fontWeight: 400,
    marginRight: "1rem",
  },

  popOverPaper: {
    borderRadius: "1rem",

    minWidth: "25rem",
    maxWidth: "50rem",
    borderStyle: "solid",
    borderColor: theme.palette.common.blackFive,
    borderWidth: "2px",
    backgroundColor: `${theme.palette.common.darkDarkRed}d0`,
    backdropFilter: "blur(10px)",
    "-webkit-backdrop-filter": "blur(10px)",
  },

  popOverHead: {
    paddingTop: "0.25rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0.25rem",
    position: "sticky",
    top: 0,
    zIndex: 2,
    backgroundColor: `${theme.palette.common.blackTwo}`,
  },

  popOverCont: {
    overflowY: "auto",
    paddingTop: "1rem",
    paddingLeft: "1rem",
    paddingRight: "1rem",
    paddingBottom: "0.75rem",
    maxHeight: "78vh",
  },

  sectionAvatarBox: {
    display: "inline-block",
    padding: "0.25rem",
    marginRight: "0.5rem",
  },

  sectionAvatar: {
    backgroundColor: theme.palette.common.blackThree,
    color: theme.palette.common.blackOne,
    height: "1.75rem",
    width: "1.75rem",
  },
  sectionAvatarDineIn: {
    backgroundColor: `${theme.palette.common.dineInMed} !important`,
  },
  sectionAvatarReceived: {
    backgroundColor: `${theme.palette.common.received} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  sectionAvatarPreparing: {
    backgroundColor: `${theme.palette.common.preparing} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  sectionAvatarReady: {
    backgroundColor: `${theme.palette.common.ready} !important`,
    color: `${theme.palette.common.white} !important`,
  },
  sectionAvatarDone: {
    backgroundColor: `${theme.palette.common.done} !important`,
    color: `${theme.palette.common.white} !important`,
  },

  sectionAvatarText: {
    fontWeight: 700,
    marginTop: "0.1rem",
    fontSize: "1.25rem",
  },
}))

const StyledBadge = withStyles(theme => ({
  badge: {
    transform: "scale(1) translate(50%, -50%)",
    backgroundColor: `${theme.palette.common.offWhite} !important`,
    color: `${theme.palette.common.black} !important`,
    fontWeight: 700,
    padding: "0.25rem",
    fontSize: "1rem",
    right: 3,
  },
}))(Badge)

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 3,
    borderRadius: 0,
  },
  colorPrimary: {
    backgroundColor: theme.palette.common.blackFour,
  },
  bar: {
    borderRadius: 0,
    backgroundColor: theme.palette.common.offWhite,
  },
}))(LinearProgress)

const OrderListItem = ({
  orderItem,
  ordersIn,
  user,
  dispatchFeedback,
  setAlarm,
  setOrdersIn,
  kitchenSettings,
  section,
  printer,
  setCurrentOrdCount,
  selectedOrderStateOption,
  selectedDetailLvl,
  isAllLoading,
  setIsAllLoading,
  setPrintAlert,
}) => {
  const [anchorElWaiting, setAnchorElWaiting] = React.useState(null)
  const [anchorElDetails, setAnchorElDetails] = React.useState(null)

  const classes = useStyles({ selectedDetailLvl })

  return (
    <div className={classes.gridSizing}>
      <Box classes={{ root: classes.orderListItemBoxOuter }}>
        <Box
          classes={{
            root: clsx(classes.orderListItemBox, {
              [classes.dineInMainBox]: orderItem.orderType === "dineIn",
            }),
          }}
        >
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            classes={{ root: classes.headerGrid }}
          >
            <Grid item>
              <Typography variant="h5" classes={{ root: classes.orderNoText }}>
                {`${
                  orderItem.orderType === "dineIn"
                    ? `TBL #${orderItem.orderTableNumber}`
                    : `#${orderItem.id}`
                }`}
              </Typography>

              {orderItem.orderType === "dineIn" ? (
                <>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.textLabel }}
                  >
                    {`DINE-IN #${orderItem.id}`}
                  </Typography>
                </>
              ) : orderItem.orderType === "takeAway" ? (
                <>
                  <Typography
                    variant="body1"
                    classes={{ root: classes.textLabel }}
                  >
                    {"TAKE AWAY"}
                  </Typography>
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item>
              {orderItem.waiting ? (
                <IconButton
                  aria-describedby={
                    Boolean(anchorElWaiting) ? "waiting-popover" : undefined
                  }
                  onClick={event => {
                    setAnchorElWaiting(event.currentTarget)
                  }}
                  variant="outlined"
                  classes={{ root: classes.butMain }}
                >
                  <HourglassBottomOutlinedIcon
                    classes={{ root: classes.iconSize }}
                  />
                </IconButton>
              ) : (
                <></>
              )}

              <IconButton
                aria-describedby={
                  Boolean(anchorElDetails) ? "details-popover" : undefined
                }
                onClick={event => {
                  setAnchorElDetails(event.currentTarget)
                }}
                variant="outlined"
                classes={{ root: classes.butMain }}
              >
                <CenterFocusStrongOutlinedIcon
                  classes={{ root: classes.iconSize }}
                />
              </IconButton>
            </Grid>
          </Grid>

          {orderItem.sectionsObj[section].sectionStatus === "Preparing" ? (
            <BorderLinearProgress
              classes={{
                bar: clsx({
                  [classes.progressWarning]:
                    orderItem.sectionsObj[section].lastUpdateTimeInMin >
                      0.8 * kitchenSettings.maxPreparingTime &&
                    orderItem.sectionsObj[section].lastUpdateTimeInMin <
                      kitchenSettings.maxPreparingTime,
                  [classes.progressDanger]:
                    orderItem.sectionsObj[section].lastUpdateTimeInMin >=
                    kitchenSettings.maxPreparingTime,
                }),
              }}
              variant="determinate"
              value={
                orderItem.sectionsObj[section].lastUpdateTimeInMin >=
                kitchenSettings.maxPreparingTime
                  ? 100
                  : (orderItem.sectionsObj[section].lastUpdateTimeInMin /
                      kitchenSettings.maxPreparingTime) *
                    100
              }
            />
          ) : (
            <Box classes={{ root: classes.dividerLine }}></Box>
          )}

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            classes={{
              root: clsx(classes.statusGrid, {
                [classes.statusGridAlarm]:
                  orderItem.sectionsObj[section].alarm &&
                  orderItem.sectionsObj[section].sectionStatus === "Received",
                [classes.statusGridWarning]:
                  orderItem.sectionsObj[section].warning &&
                  orderItem.sectionsObj[section].sectionStatus === "Received",
                [classes.statusGridPreparing]:
                  orderItem.sectionsObj[section].sectionStatus === "Preparing",
                [classes.statusGridReady]:
                  orderItem.sectionsObj[section].sectionStatus === "Ready",
                [classes.statusGridDone]:
                  orderItem.sectionsObj[section].sectionStatus === "Done",
              }),
            }}
          >
            <Grid item>
              <Typography
                variant="body1"
                classes={{ root: classes.statusText }}
              >
                {orderItem.sectionsObj[section].sectionStatus}
              </Typography>
            </Grid>
            {orderItem.sectionsObj[section].sectionStatus !== "Done" ? (
              <Grid item>
                <Typography
                  variant="body1"
                  classes={{ root: classes.timeText }}
                >
                  {`Due in ${getTimeText(orderItem.timeToOrderDue)}`}
                </Typography>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            classes={{
              root: clsx(classes.ordStatusGrid, {
                [classes.statusGridPreparing]: orderItem.status === "Preparing",
                [classes.statusGridReady]: orderItem.status === "Ready",
                [classes.statusGridDone]: orderItem.status === "Done",
              }),
            }}
          >
            <Grid item>
              <Typography
                variant="body1"
                classes={{ root: classes.ordStatusHeading }}
              >
                {"ORDER STATUS"}
              </Typography>
            </Grid>

            <Grid item>
              <Typography
                variant="body1"
                classes={{ root: classes.ordStatusText }}
              >
                {orderItem.status}
              </Typography>
            </Grid>
          </Grid>

          <Box classes={{ root: classes.detailsBox }}>
            {orderItem.orderNotes ? (
              <Box>
                <Typography
                  variant="h6"
                  classes={{ root: classes.itemHeading }}
                >
                  {"ORDER NOTES"}
                </Typography>
                <Typography
                  variant="body1"
                  classes={{ root: classes.descriptionText }}
                >
                  {orderItem.orderNotes}
                </Typography>
              </Box>
            ) : (
              <></>
            )}

            {orderItem.sections.map((sectionItem, index) =>
              sectionItem.sectionHasItems && sectionItem.slug === section ? (
                <OrderItemLine
                  key={`${orderItem.id}-${index}`}
                  orderItem={orderItem}
                  sectionItem={sectionItem}
                />
              ) : null
            )}
          </Box>

          <OrderListItemFooter
            orderItem={orderItem}
            ordersIn={ordersIn}
            user={user}
            dispatchFeedback={dispatchFeedback}
            setAlarm={setAlarm}
            setOrdersIn={setOrdersIn}
            kitchenSettings={kitchenSettings}
            section={section}
            printer={printer}
            setCurrentOrdCount={setCurrentOrdCount}
            selectedDetailLvl={selectedDetailLvl}
            isAllLoading={isAllLoading}
            setIsAllLoading={setIsAllLoading}
            setPrintAlert={setPrintAlert}
          />
        </Box>
      </Box>
      <Popover
        id={Boolean(anchorElWaiting) ? "waiting-popover" : undefined}
        open={Boolean(anchorElWaiting)}
        anchorEl={anchorElWaiting}
        classes={{ paper: classes.popOverPaper }}
        onClose={() => {
          setAnchorElWaiting(null)
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          classes={{ root: classes.popOverHead }}
        >
          <Grid item>
            <Typography
              variant="h5"
              classes={{ root: classes.waitingTextHead }}
            >
              {"WAITING FOR"}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              variant="outlined"
              classes={{ root: classes.butMain }}
              onClick={() => {
                setAnchorElWaiting(null)
              }}
            >
              <CloseOutlinedIcon classes={{ root: classes.iconSize }} />
            </IconButton>
          </Grid>
        </Grid>
        <Box classes={{ root: classes.popOverCont }}>
          <Typography variant="h6">{`${orderItem.waitingFor}`}</Typography>
        </Box>
      </Popover>

      <Popover
        id={Boolean(anchorElDetails) ? "details-popover" : undefined}
        open={Boolean(anchorElDetails)}
        anchorEl={anchorElDetails}
        classes={{ paper: classes.popOverPaper }}
        onClose={() => {
          setAnchorElDetails(null)
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          classes={{ root: classes.popOverHead }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  variant="h5"
                  classes={{ root: classes.waitingTextHead }}
                >
                  {`${
                    orderItem.orderType === "dineIn"
                      ? `TBL #${orderItem.orderTableNumber}`
                      : `#${orderItem.id}`
                  }`}
                </Typography>
              </Grid>
              {orderItem.orderType === "dineIn" ? (
                <Grid item>
                  <Typography
                    variant="h5"
                    classes={{ root: classes.waitingTextHeadSecond }}
                  >
                    {`${
                      orderItem.orderType === "dineIn"
                        ? `(#${orderItem.id})`
                        : ` `
                    }`}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}

              <Grid item>
                {orderItem.sections.map((section, index) => (
                  <Box key={index} classes={{ root: classes.sectionAvatarBox }}>
                    <StyledBadge
                      badgeContent={section.sectionItemsCount}
                      overlap="rectangular"
                    >
                      <Avatar
                        classes={{
                          root: clsx(classes.sectionAvatar, {
                            [classes.sectionAvatarDineIn]:
                              orderItem.orderType === "dineIn" &&
                              section.sectionItemsCount < 1,
                            [classes.sectionAvatarReceived]:
                              section.sectionStatus === "Received" &&
                              section.sectionItemsCount > 0,
                            [classes.sectionAvatarPreparing]:
                              section.sectionStatus === "Preparing" &&
                              section.sectionItemsCount > 0,
                            [classes.sectionAvatarReady]:
                              section.sectionStatus === "Ready" &&
                              section.sectionItemsCount > 0,
                            [classes.sectionAvatarDone]:
                              section.sectionStatus === "Done" &&
                              section.sectionItemsCount > 0,
                          }),
                        }}
                      >
                        <Typography
                          variant="body1"
                          classes={{ root: classes.sectionAvatarText }}
                        >
                          {section.name.slice(0, 1)}
                        </Typography>
                      </Avatar>
                    </StyledBadge>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              variant="outlined"
              classes={{ root: classes.butMain }}
              onClick={() => {
                setAnchorElDetails(null)
              }}
            >
              <CloseOutlinedIcon classes={{ root: classes.iconSize }} />
            </IconButton>
          </Grid>
        </Grid>

        <Box classes={{ root: classes.popOverCont }}>
          {orderItem.orderNotes ? (
            <Box>
              <Typography variant="h6" classes={{ root: classes.itemHeading }}>
                {"ORDER NOTES"}
              </Typography>
              <Typography
                variant="body1"
                classes={{ root: classes.descriptionText }}
              >
                {orderItem.orderNotes}
              </Typography>
            </Box>
          ) : (
            <></>
          )}

          {orderItem.sections.map((sectionItem, index) =>
            sectionItem.sectionHasItems && sectionItem.slug === section ? (
              <OrderItemLine
                key={`${orderItem.id}-${index}`}
                orderItem={orderItem}
                sectionItem={sectionItem}
              />
            ) : null
          )}
        </Box>
      </Popover>
    </div>
  )
}

export default OrderListItem
