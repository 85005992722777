import * as React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"

import MainOrderList from "./mainOrderList"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  mainContainer: {
    width: "100%",
    paddingTop: "4rem",

    [theme.breakpoints.down("xs")]: {
      paddingTop: "4rem",
    },
  },

  inMainGrid: {},

  gridBox: {
    backgroundColor: theme.palette.common.blackThree,

    height: "calc(100vh  - 65px)",
    overflowY: "auto",
  },

  alertTitle: {
    fontSize: "1.75rem !important",
    marginTop: "0.5rem",
    lineHeight: "2rem !important",
  },

  dialogText: {
    fontSize: "1.25rem !important",
    color: theme.palette.common.white,
    lineHeight: "1.5rem",
  },
  alertTitCont: {
    paddingBottom: 0,
  },
  butGridCont: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    paddingBottom: "1rem",
  },

  butGrid: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  butDilog: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },

  alertPaper: {
    backgroundColor: theme.palette.common.redSelected,
  },
}))

const MainSectionInCont = ({
  user,
  printer,
  dispatchFeedback,
  setAlarm,
  kitchenSettings,
  ordersIn,
  setOrdersIn,
  section,
  setCurrentOrdCount,
  selectedOrderStateOption,
  selectedDetailLvl,
}) => {
  const [open, setOpen] = React.useState(false)
  const [printAlert, setPrintAlert] = React.useState("")
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
    setPrintAlert("")
  }

  React.useEffect(() => {
    if (printAlert !== "") {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }, [printAlert])

  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        classes={{ root: classes.mainContainer }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          classes={{ root: classes.inMainGrid }}
        >
          <Box classes={{ root: classes.gridBox }}>
            <MainOrderList
              ordersIn={ordersIn}
              user={user}
              dispatchFeedback={dispatchFeedback}
              setAlarm={setAlarm}
              setOrdersIn={setOrdersIn}
              kitchenSettings={kitchenSettings}
              section={section}
              printer={printer}
              setCurrentOrdCount={setCurrentOrdCount}
              selectedOrderStateOption={selectedOrderStateOption}
              selectedDetailLvl={selectedDetailLvl}
              setPrintAlert={setPrintAlert}
            />
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xs"}
        fullWidth
        aria-labelledby={`printer-error-title`}
        aria-describedby={`printer-error-description`}
        classes={{ paper: classes.alertPaper }}
      >
        <DialogTitle
          id={`printer-error-title`}
          classes={{ root: classes.alertTitCont }}
        >
          <Box classes={{ root: classes.alertTitle }}>{"Printer Error"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText
            classes={{ root: classes.dialogText }}
            id={`printer-error-description`}
          >
            {`${printAlert}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            classes={{ root: classes.butGridCont }}
          >
            <Grid item xs={6} classes={{ root: classes.butGrid }}>
              <Button
                onClick={handleClose}
                variant="contained"
                size="large"
                color="primary"
                disableElevation
                fullWidth
                classes={{ root: classes.butDilog }}
              >
                <Typography variant="body1">{"OK"}</Typography>
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default MainSectionInCont
