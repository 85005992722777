import * as React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

import SingleOrderItemLine from "./singleOrderItemLine"

import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  inGrid: {
    width: "100%",
    marginBottom: "0.5rem",
    overflow: "auto",
    position: "relative",
  },

  contBox: {
    paddingTop: 0,
    marginTop: "-1.25rem",
  },
}))

const OrderItemLine = ({ orderItem, sectionItem }) => {
  const classes = useStyles()
  return (
    <Grid item classes={{ root: classes.inGrid }}>
      <Box classes={{ root: classes.contBox }}>
        {orderItem.items.map((item, index) =>
          item.section.slug === sectionItem.slug ? (
            <SingleOrderItemLine key={index} item={item} />
          ) : null
        )}
      </Box>
    </Grid>
  )
}

export default OrderItemLine
