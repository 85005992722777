export const getAlarm = (ordersIn, kitchenSettings, section) => {
  let ordersInCopy = JSON.parse(JSON.stringify(ordersIn))

  let takeAwayAlarm = false
  let dineInAlarm = false
  let alarm = ""
  let ordCount = 0

  for (let i = 0; i < ordersInCopy.length; i++) {
    ordersInCopy[i].sectionsObj[section].warning = false
    ordersInCopy[i].sectionsObj[section].alarm = false
    let newTime =
      Date.now() - parseInt(ordersInCopy[i].sectionsObj[section].lastUpdateTime)
    ordersInCopy[i].sectionsObj[section].lastUpdateTimeInMin = Math.round(
      newTime / (60 * 1000)
    )

    if (ordersInCopy[i].sectionsObj[section].sectionStatus !== "Done") {
      if (
        ordersInCopy[i].timeToOrderDue <= kitchenSettings.takeAwayMinToWarning
      ) {
        let show = true
        let waitingFor = []
        if (ordersInCopy[i].sectionsObj[section].waitFor) {
          for (
            let kk = 0;
            kk < ordersInCopy[i].sectionsObj[section].waitFor.length;
            kk++
          ) {
            let sectionStatusTest =
              ordersInCopy[i].sectionsObj[
                ordersInCopy[i].sectionsObj[section].waitFor[kk]
              ].sectionStatus
            if (
              sectionStatusTest === "Received" ||
              sectionStatusTest === "Preparing"
            ) {
              if (
                ordersInCopy[i].sectionsObj[section].sectionStatus ===
                "Received"
              ) {
                let sectionName =
                  ordersInCopy[i].sectionsObj[section].waitFor[kk]
                show = false
                waitingFor.push(
                  `${
                    sectionName.charAt(0).toUpperCase() + sectionName.slice(1)
                  }`
                )
              }
            }
          }
        }
        if (show) {
          ordersInCopy[i].filterOpt = "Current"
          ordCount++
          if (ordersInCopy[i].orderType === "takeAway") {
            if (
              ordersInCopy[i].sectionsObj[section].sectionStatus ===
                "Received" &&
              ordersInCopy[i].timeToOrderDue <=
                kitchenSettings.takeAwayMinToAlarm
            ) {
              takeAwayAlarm = true
              ordersInCopy[i].sectionsObj[section].alarm = true
            } else if (
              ordersInCopy[i].sectionsObj[section].sectionStatus ===
                "Received" &&
              ordersInCopy[i].timeToOrderDue >
                kitchenSettings.takeAwayMinToAlarm &&
              ordersInCopy[i].timeToOrderDue <=
                kitchenSettings.takeAwayMinToWarning
            ) {
              ordersInCopy[i].sectionsObj[section].warning = true
            }
          } else if (ordersInCopy[i].orderType === "dineIn") {
            let dineInTimeToOrderDue =
              (ordersInCopy[i].timeToOrderDue -
                kitchenSettings.dineInPreparingTime) *
              -1
            if (
              ordersInCopy[i].sectionsObj[section].sectionStatus ===
                "Received" &&
              dineInTimeToOrderDue >= kitchenSettings.dineInMinToWarning &&
              dineInTimeToOrderDue < kitchenSettings.dineInMinToAlarm
            ) {
              ordersInCopy[i].sectionsObj[section].warning = true
            } else if (
              ordersInCopy[i].sectionsObj[section].sectionStatus ===
                "Received" &&
              dineInTimeToOrderDue >= kitchenSettings.dineInMinToAlarm
            ) {
              dineInAlarm = true
              ordersInCopy[i].sectionsObj[section].alarm = true
            }
          }
        } else {
          ordersInCopy[i].filterOpt = "Future"
          if (waitingFor.length > 0) {
            ordersInCopy[i].waiting = true
            ordersInCopy[i].waitingFor = waitingFor
          }
        }
      } else if (
        ordersInCopy[i].sectionsObj[section].sectionStatus === "Preparing" ||
        ordersInCopy[i].sectionsObj[section].sectionStatus === "Ready"
      ) {
        ordCount++
        ordersInCopy[i].filterOpt = "Current"
      } else {
        ordersInCopy[i].filterOpt = "Future"
      }
    } else {
      ordersInCopy[i].filterOpt = "Past"
    }

    let sectionsArrayKeys = Object.keys(ordersInCopy[i].sectionsObj)
    let othersWaiting = false
    for (let jj = 0; jj < sectionsArrayKeys.length; jj++) {
      let waitFor = ordersInCopy[i].sectionsObj[sectionsArrayKeys[jj]].waitFor

      if (waitFor) {
        if (
          waitFor.includes(section) &&
          ordersInCopy[i].sectionsObj[sectionsArrayKeys[jj]].sectionHasItems
        ) {
          othersWaiting = true
        }
      }
    }
    ordersInCopy[i].othersWaiting = othersWaiting
  }

  if (takeAwayAlarm && dineInAlarm) {
    alarm = "ORD DUE"
  } else if (takeAwayAlarm) {
    alarm = "ORD DUE"
  } else if (dineInAlarm) {
    alarm = "ORD DUE"
  }

  return { alarm, orderRes: ordersInCopy, ordCount }
}

export const sortOrder = (dataIn, section) => {
  dataIn.sort(function (a, b) {
    if (
      a.sectionsObj[section].sectionStatus === "Done" &&
      b.sectionsObj[section].sectionStatus === "Done"
    ) {
      return b.timeToOrderDue - a.timeToOrderDue
    } else {
      return a.timeToOrderDue - b.timeToOrderDue
    }
  })
}

export const setOrdTimesNAlarm = (ordersIn, kitchenSettings, section) => {
  let ordersInCopy = JSON.parse(JSON.stringify(ordersIn))

  for (let i = 0; i < ordersInCopy.length; i++) {
    let newTime = Date.now() - parseInt(ordersInCopy[i].lastUpdateTime)
    ordersInCopy[i].lastUpdateTimeInMin = Math.round(newTime / (60 * 1000))
    ordersInCopy[i].lastUpdateTimeInSec = Math.round(newTime / 1000)

    if (ordersInCopy[i].orderType === "takeAway") {
      let newTimeToPickUp = Math.round(
        (new Date(
          ordersInCopy[i].orderPickUpDate.fullYear,
          ordersInCopy[i].orderPickUpDate.monthInd,
          ordersInCopy[i].orderPickUpDate.dayOfMonth,
          0,
          ordersInCopy[i].orderPickUpTime
        ) -
          Date.now()) /
          (60 * 1000)
      )

      ordersInCopy[i].timeToPickUp = newTimeToPickUp
      ordersInCopy[i].timeToOrderDue = newTimeToPickUp
    } else if (ordersInCopy[i].orderType === "dineIn") {
      let newTimeToOrderDue = Math.round(
        (parseInt(ordersInCopy[i].orderTime) - Date.now()) / (60 * 1000)
      )
      ordersInCopy[i].timeToOrderDue =
        newTimeToOrderDue + kitchenSettings.dineInPreparingTime
    }
  }

  let { alarm, orderRes, ordCount } = getAlarm(
    ordersInCopy,
    kitchenSettings,
    section
  )

  sortOrder(orderRes, section)

  return { orderRes, alarm, ordCount }
}

export const refreshOrdTimesNAlarm = (ordersIn, kitchenSettings, section) => {
  let { orderRes, alarm, ordCount } = setOrdTimesNAlarm(
    ordersIn,
    kitchenSettings,
    section
  )

  let counter = 0
  let ordersInCopy = []
  let orderResCopy = [...orderRes]

  for (let i = 0; i < orderResCopy.length; i++) {
    if (orderResCopy[i].status !== "Done") {
      ordersInCopy.push(orderResCopy[i])
    } else if (orderResCopy[i].status === "Done" && counter < 20) {
      counter++
      ordersInCopy.push(orderResCopy[i])
    }
  }
  return { orderRes: ordersInCopy, alarm, ordCount }
}

export const addSingleOrder = (
  ordersIn,
  newOrder,
  kitchenSettings,
  section
) => {
  let ordersInCopy = JSON.parse(JSON.stringify(ordersIn))
  let isOrdersChanged = false
  let existingOrdInd

  existingOrdInd = ordersInCopy.findIndex(element => element.id === newOrder.id)

  if (existingOrdInd > -1) {
    if (ordersInCopy[existingOrdInd].orderVersion !== newOrder.orderVersion) {
      isOrdersChanged = true
      ordersInCopy[existingOrdInd] = newOrder
    }
  } else {
    let sectionIndex = newOrder.sections.findIndex(obj => {
      return obj.slug === section
    })
    if (sectionIndex > -1) {
      if (newOrder.sections[sectionIndex].sectionHasItems) {
        isOrdersChanged = true
        ordersInCopy.push(newOrder)
      }
    }
  }
  if (isOrdersChanged) {
    let { orderRes, alarm, ordCount } = refreshOrdTimesNAlarm(
      ordersInCopy,
      kitchenSettings,
      section
    )

    return { orderRes, alarm, ordCount }
  } else {
    return null
  }
}
